'use strict';

appServices.service('FormService', [
  '$q',
  '$timeout',
  '$http',
  '$filter',
  '$window',
  'ValidationService',
  'NwuiAnalyticsService',
  'MaskService',
  'FixedsliderService',
  'locale',
  'NwuiSession',
  'NwButtonService',
  'FormInstanceID',
  'KYCInstanceID',
  'NwuiLocalizeService',
  function(
    $q,
    $timeout,
    $http,
    $filter,
    $window,
    ValidationService,
    NwuiAnalyticsService,
    MaskService,
    FixedsliderService,
    locale,
    NwuiSession,
    NwButtonService,
    FormInstanceID,
    KYCInstanceID,
    NwuiLocalizeService
  ) {

    var self = this;
    var save_step_func;
    var steps_saved = {};

    var observer_cbs = {
      comp: [],
      step: [],
      error: [],
      showing_step: [],
      province: [],
      tax_benefits: [],
      input_error: {},
      input_clear: {},
      input_row: {},
      input_step: {},
      input_validate: {},
      select_open: {}
    };

    var notify_observers = function(observers) {
      angular.forEach(observer_cbs[observers], function(cb) {
        cb();
      });
    };

    var notify_province_observers = function(field) {
      angular.forEach(observer_cbs.province, function(cb) {
        cb(field);
      });
    };

    var notify_tax_benefits_observers = function(field) {
      angular.forEach(observer_cbs.tax_benefits, function(cb) {
        cb(field);
      });
    };

    this.validatorTrail = {};

    this.isValidatorNotRepeated = function(model) {
      if (!this.validatorTrail[model]) {
        this.validatorTrail[model] = true;
        return true;
      }

      return false;
    };

    this.form_name;
    this.custodian_slug = null;
    this.custodian_translation_slug = '';
    this.answers = {};
    this.errors = {};
    this.steps = [];
    this.current_step = {};
    this.current_step_index = 0;
    this.showing_row_ifs = {};
    this.showing_step_ifs = {};
    this.isKYC = false;
    this.access_code = undefined;
    this.feeData = {};

    this.public_observer_cbs = {
      step_ifs: {},
      row_ifs: {},
      input: {}
    }

    this.set_fee_data = function(additional_info) {
      if(!additional_info) return;
      this.feeData.investmentManagementFees = $filter('NwuiPercent')(additional_info.investment_management_fees, 2);
      this.feeData.fundManagementFees = $filter('NwuiPercent')(additional_info.fund_management_fees, 2);
    }

    this.isStepSkipped = function(index) {

      return self.showing_step_ifs[index] === false;
    }

    this.getSkippedStepsCount = function(index) {

      var step_ifs = self.showing_step_ifs;
      var skipped_count = 0;

      var count = 0;
      while(count <= index) {
        if(step_ifs[count] === false)
          skipped_count++;

        count++;
      }

      return skipped_count;

    }

    this.isStepTheFirstVisibleStep = function(index) {
      return +index === +this.getFirstVisibleStepIndex();
    }

    this.getFirstVisibleStepIndex = function() {
      var firstVisibleIndex = 0;
      for (var i = 0; i < this.steps.length; i++) {
        if(this.showing_step_ifs[i] || (this.showing_step_ifs[i] === undefined)) {
          firstVisibleIndex = +i;
          break;
        }
      }
      return firstVisibleIndex;
    }

    this.register_form_complete_observer_cb = function(cb) {
      observer_cbs.comp.push(cb);
    };

    this.register_step_change_observer_cb = function(cb) {
      observer_cbs.step.push(cb);
    };

    this.register_error_observer_cb = function(cb) {
      observer_cbs.error.push(cb);
    };

    this.register_showing_step_observer_cb = function(cb) {
      observer_cbs.showing_step.push(cb);
    };

    this.register_province_observer_cb = function(cb) {
      observer_cbs.province.push(cb);
    }

    this.register_tax_benefits_observer_cb = function(cb) {
      observer_cbs.tax_benefits.push(cb);
    }

    this.register_input_error_observer_cb = function(cb, model) {
      observer_cbs.input_error[model] = cb;
    };

    this.register_input_clear_observer_cb = function(cb, model) {
      observer_cbs.input_clear[model] = cb;
    };

    this.register_input_row_observer_cb = function(cb, model) {
      observer_cbs.input_row[model] = cb;
    }

    this.register_input_step_observer_cb = function(cb, model) {
      observer_cbs.input_step[model] = cb;
    }

    this.register_input_validate_observer_cb = function(cb, model) {
      observer_cbs.input_validate[model] = cb;
    }

    this.register_row_observer_cb = function(cb, model) {

      if(!this.public_observer_cbs.row_ifs[model]) this.public_observer_cbs.row_ifs[model] = [];
      this.public_observer_cbs.row_ifs[model].push(cb);

    }

    this.register_step_observer_cb = function(cb, model) {

      if(!this.public_observer_cbs.step_ifs[model]) this.public_observer_cbs.step_ifs[model] = [];
      this.public_observer_cbs.step_ifs[model].push(cb);

    }

    this.set_answers = function(answers) {
      this.answers = answers;
    }

    this.get_answers = function() {
      return this.answers;
    };

    this.set_save_func = function(save_func) {
      save_step_func = save_func;
    }

    this.save_step = function(step, cb, cb_cancel) {
      save_step_func(step, cb, cb_cancel);
    }

    this.set_steps = function(steps) {
      this.steps = steps;
    }

    this.set_custodian_slug = function(custodian_slug) {
      this.custodian_slug = custodian_slug;
      this.custodian_translation_slug = (custodian_slug) ? '.' + custodian_slug : '';
    }

    this.set_error = function(field, error) {
      if(error)
        this.errors[field.input.model] = process_error(field, error);
      else
        delete this.errors[field.input.model];

      if(observer_cbs.input_error[field.input.model])
        observer_cbs.input_error[field.input.model]();

      notify_observers('error');

    }

    this.remove_error = function(model) {
      delete this.errors[model];
    }

    this.notify_province = function(field) {
      notify_province_observers(field);
    }

    this.notify_tax_benefits = function(field) {
      notify_tax_benefits_observers(field);
    }

    this.add_showing_row_if = function(model) {
      this.showing_row_ifs[model] = true;
    }

    this.remove_showing_row_if = function(model, fields) {
      this.showing_row_ifs[model] = false;

      fields.forEach(function(field, i) {
        self.clear_error(field.input.model);
        // self.clear_input(field.input.model);
      });
    }

    this.add_showing_step_if = function(index) {
      this.showing_step_ifs[index] = true;

      notify_observers('showing_step');
    }

    this.remove_showing_step_if = function(index) {
      this.showing_step_ifs[index] = false;

      notify_observers('showing_step');
    }

    this.add_close_select_observer = function(cb, model) {
      observer_cbs.select_open[model] = cb;
    }

    this.remove_close_select_observer = function(model) {
      delete observer_cbs.select_open[model];
    }

    this.set_step_by_index = function(i) {

      i++;
      var found = false;
      while(!found) {
        i--;

        if(!(this.showing_step_ifs[i] === false))
          found = true;
      }

      if(this.steps[i] === undefined) return;

      var prev_step_index = this.current_step_index;

      this.current_step = this.steps[i];
      this.current_step_index = i;

      for(var j = 0; j < this.steps[i].rows.length; j++) {
        for(var k = 0; k < this.steps[i].rows[j].fields.length; k++) {

          if(observer_cbs.input_step[this.steps[i].rows[j].fields[k].input.model])
            observer_cbs.input_step[this.steps[i].rows[j].fields[k].input.model](true);

        }
      }

      //hide future steps
      if(prev_step_index > i) {
        if(!this.steps[prev_step_index]) return;
        for(var j = 0; j < this.steps[prev_step_index].rows.length; j++) {
          for(var k = 0; k < this.steps[prev_step_index].rows[j].fields.length; k++) {

            if(observer_cbs.input_step[this.steps[prev_step_index].rows[j].fields[k].input.model])
              observer_cbs.input_step[this.steps[prev_step_index].rows[j].fields[k].input.model](false);

          }
        }
      }

      this.clear_all_errors();
      notify_observers('step');

    }

    this.blockKYCCondition = function() {
      var blocked =
        // Org supports if user should be blocked on KYC score
        (organization_data.join_data.questionnaire.check_if_user_can_be_blocked === true) &&
        // is on KYC
        this.isKYC &&
        // Evalue KYC score based to block if not in range
        this.ngIfEvaluator(this.get_answers(), organization_data.join_data.questionnaire.answers_to_check.ng_if);

      return blocked;
    };

    this.ngIfEvaluator = function(values, ng_if) {
      var operator;
      var conditions;

      if (ng_if.ng_if_model) {
        /**
          * Allows to compare undefined values with null.
          * `ngIfEvaluator` is being used on models where condition may be null,
          * meaning that we expect the value to be falsy, but sometimes it may not
          * be present in the object with supplied values.
        */
        switch (ng_if.ng_if_operator) {
          case 'gt':
            return values[ng_if.ng_if_model] > ng_if.ng_if_value;
            break;
          case 'gte':
            return values[ng_if.ng_if_model] >= ng_if.ng_if_value;
            break;
          case 'lt':
            return values[ng_if.ng_if_model] < ng_if.ng_if_value;
            break;
          case 'lte':
            return values[ng_if.ng_if_model] <= ng_if.ng_if_value;
            break;
          case 'ne':
            return values[ng_if.ng_if_model] != ng_if.ng_if_value;
            break;
          case 'regex':
            const regex = new RegExp(ng_if.ng_if_value, 'gi');
            return regex.test(values[ng_if.ng_if_model]);
            break;
          default:
            return values[ng_if.ng_if_model] === ng_if.ng_if_value;
            break;
        }
      } else if (ng_if.and) {
        operator = '&&';
        conditions = ng_if.and;
      } else if (ng_if.or) {
        operator = '||';
        conditions = ng_if.or;
      } else {
        return true; // No ng_if
      }

      var conditional_string = '(';

      const count = conditions.length;
      for (var i = 0; i < count; i++) {
        conditional_string += this.ngIfEvaluator(values, conditions[i]);
        if (i !== count - 1) conditional_string += operator;
      };

      conditional_string += ')';
      /* eslint-disable no-eval */
      return eval(conditional_string);
    }

    this.go_to_next_step = function() {

      var found = false;
      var index = this.current_step_index;
      while(!found) {
        index++;

        if(!(this.showing_step_ifs[index] === false))
          found = true;
      }
      this.set_step_by_index(index);

    }

    this.go_to_prev_step = function() {
      const clientUserData = NwuiSession.userData.user;
      this.clear_all_errors();
      if (clientUserData.use_rpq && this.current_step_index === 2) {
        let accountsList = '';
        if (this.answers.ownershipType === 'joint') {
          if (this.answers.Joint && this.answers.JointRESPFam) {
            accountsList = 'Joint,JointRESPFam';
          } else if (this.answers.AHJoint) {
            accountsList = 'Joint';
          } else if (this.answers.AHJointRESP) {
            accountsList = 'JointRESPFam';
          }
        } else {
          accountsList = this.answers.AHAccountTypeList;
        }
        const url = clientUserData.rpq_url + `?accounts=${accountsList}&locale=${NwuiLocalizeService.getLang() === 'fr-CA' ? 'fr' : 'en'}&lang=${NwuiLocalizeService.getLang()}`;
        $window.location.href = url;
      }
      this.set_step_by_index(this.current_step_index - 1);
    }

    this.form_complete = function() {
      notify_observers('comp');
    }

    // Convert validator from string to using the validator object
    var convertValidatorObject = function(validator) {
      var convertedValidator = {
        name: 'blank',
        models: [],
        params: []
      };
      if (typeof validator === 'string') {
        convertedValidator.name = validator;
      } else {
        angular.extend(convertedValidator, validator);
      }
      // Check if validator exists, if not default to blank. KYC has a "select" validator which isn't in the service.
      if (!ValidationService[convertedValidator.name]) {
        convertedValidator.name = 'blank';
      }
      return convertedValidator;
    };

    this.showErrorWhileImpersonating = function(tenantConfig){
      return (tenantConfig && $window.sessionStorage.getItem("showErrorWhileImpersonating")
          ) ? true : false;
    }

    this.validate = function(type, field, validators, showErrorWhileImpersonating) {
      // console.log("sessionStorage", $sessionStorage)
      // Reset the validator trail if validate is called through any other way besides
      // through the observable. The idea is that when a user interacts with an input
      // that is the origin of the chain of validator triggers which get called
      // by the observable. If we don't clear our the trail, the validator will
      // only run the linked model's validator once and never agian.
      if (!type === 'cb')
        self.validatorTrail = {};
      var valid = true;
      if (this.showErrorWhileImpersonating(showErrorWhileImpersonating) || !NwuiSession.isAdvisorImpersonating()) {
        angular.forEach(validators, function(fieldValidators) {
          //if no validator
          if (!fieldValidators) fieldValidators = [convertValidatorObject('blank')];

          if (!Array.isArray(fieldValidators)) {
            fieldValidators = [fieldValidators];
          }
          var hasBlank = false;
          // Convert validators to name, model, param object and make sure there's always a
          // blank validator.
          angular.forEach(fieldValidators, function(fieldValidator, i) {
            fieldValidators[i] = convertValidatorObject(fieldValidator);
            if (fieldValidators[i].name === 'blank') {
              hasBlank = true;
            }
          });
          if (!hasBlank) {
            fieldValidators.push(convertValidatorObject('blank'));
          }
          angular.forEach(fieldValidators, function(validator) {
            // skip blank check if optional
            if (
              (
                validator.name === 'blank' ||
                validator.name === 'check'
              ) &&
              field.input.optional
            ) return;
            const modelAnswers = [];
            if (validator.models !== undefined) {
              angular.forEach(validator.models, function(model) {
                var effectedField = self.getFieldByModel(model);
                modelAnswers.push({model: effectedField, answer: self.answers[model]});
                // isValidatorNotRepeated keeps track of the trail of models which run validators
                // and if the model is entered again, it returns false and resets the trail.
                // This prevents the models from bouncing validation calls against eachother forever
                if (model !== field.model && self.isValidatorNotRepeated(field.model)) {
                  observer_cbs.input_validate[model]('cb');
                }
              });
            }
            // new
            var res;
            if (field.input.mask) {
              res = ValidationService[validator.name](MaskService.unmask(field.input.mask, field.input.answer), validator.params, modelAnswers);
              if (res === true && type === 'blur')
                res = MaskService.validate(field.input.mask, field.input.answer);
            } else {
              res = ValidationService[validator.name](field.input.answer, validator.params, modelAnswers);
            }

            if (res !== true) {
              self.set_error(field, res);
              valid = false;
            }
          });
        });
      }

      if(valid)
        self.set_error(field, false);

      return valid;

    }

    this.close_all_selects = function() {

      angular.forEach(observer_cbs.select_open, function(cb, model) {
        cb();
      });

    }

    this.getFieldByModel = function(model) {
      var breakloop = false;
      var returnModel = false;
      angular.forEach(self.current_step.rows, function(row) {
        angular.forEach(row.fields, function(field) {
          if (field.model.trim() === model.trim()) {
            returnModel = field;
            breakloop = true;
            return;
          }
        });
        if (breakloop) {
          return;
        }
      });
      return returnModel;
    };

    this.clear_all_errors = function(hidden_only) {

      angular.forEach(this.errors, function(error, model) {
        delete self.errors[model];

        if(observer_cbs.input_error[model])
          observer_cbs.input_error[model]();

        self.clear_input(model);
      });

      notify_observers('error');

    }

    this.clear_error = function(model) {

      delete self.errors[model];

      if(observer_cbs.input_error[model])
        observer_cbs.input_error[model]();

      notify_observers('error');

    }

    this.clear_input = function(model) {

      if(observer_cbs.input_clear[model])
        observer_cbs.input_clear[model]();

    }

    this.checkShow = function(ng_if) {

      var operator;
      var conditions;

      if(ng_if.ng_if_model) {

        var value = ng_if.ng_if_value_model === undefined
          ? ng_if.ng_if_value
          : self.answers[ng_if.ng_if_value_model];

        switch(ng_if.ng_if_operator) {
          case 'gt':
            return self.answers[ng_if.ng_if_model] > value;
            break;
          case 'gte':
            return self.answers[ng_if.ng_if_model] >= value;
            break;
          case 'lt':
            return self.answers[ng_if.ng_if_model] < value;
            break;
          case 'lte':
            return self.answers[ng_if.ng_if_model] <= value;
            break;
          case 'ne':
            return self.answers[ng_if.ng_if_model] != value;
            break;
          case 'regex':
            const regex = new RegExp(value, 'gi');
            return regex.test(self.answers[ng_if.ng_if_model]);
            break;
          default:
            return self.answers[ng_if.ng_if_model] == value;
        }

      } else if (ng_if.and){
        operator = '&&';
        conditions = ng_if.and;
      } else if(ng_if.or) {
        operator = '||';
        conditions = ng_if.or;
      } else if(ng_if.gt) {
        operator = '>';
        conditions = ng_if.gt;
      } else {
        return true; //No ng_if
      }

      var conditional_string = '(';

      var count = conditions.length;
      for (var i = 0; i < count; i++){
        conditional_string += self.checkShow(conditions[i]);
        if(i !== count - 1) conditional_string += operator;
      };

      conditional_string += ')';
      return eval(conditional_string);

    }

    /* private */
    var process_error = function(field, error) {
      if (field.label_i18n) {
        if (field.input.model === 'accounts') {
          error.label = locale.getString('forms.accounts.recommended');
        } else {
          error.label = locale.getString(field.label_i18n);
        }
      } else {
        error.label = field.label;
      }

      if (error.error_slug) {
        error.message = locale.getString('nwui.common.server_errors.' + error.error_slug, error.i18n_data);
      } else {
        error.message = error.message.replace('%field.label%', field.label);
      }

      return error;
    }

    function parseConditions(answers, ng_if, showConsole) {
      if (!ng_if) {
        return true;
      }
      if (showConsole) console.log('parse block', ng_if);

      if (ng_if.or) {
        return ng_if.or.some(function(item) {
          return parseIndividualCondition(answers, item, showConsole);
        });
      } else if (ng_if.and) {
        return ng_if.and.every(function(item) {
          return parseIndividualCondition(answers, item, showConsole);
        });
      } else {
        return parseIndividualCondition(answers, ng_if, showConsole);
      }
    }

    function parseIndividualCondition(answers, item, showConsole) {
      if (showConsole) console.log('parsing ind condition', item);

      if (item.ng_if_model) {
        var value = item.ng_if_value_model === undefined
          ? item.ng_if_value
          : answers[item.ng_if_value_model];

        if (showConsole)
          console.log('parseIndividualCondition', answers[item.ng_if_model]);

        switch(item.ng_if_operator) {
          case 'gt':
            return answers[item.ng_if_model] > value;
            break;
          case 'gte':
            return answers[item.ng_if_model] >= value;
            break;
          case 'lt':
            return answers[item.ng_if_model] < value;
            break;
          case 'lte':
            return answers[item.ng_if_model] <= value;
            break;
          case 'ne':
            return answers[item.ng_if_model] != value;
            break;
          case 'regex':
            const regex = new RegExp(value, 'gi');
            return regex.test(answers[item.ng_if_model]);
            break;
          default:
            return answers[item.ng_if_model] == value;
        }
      } else if (item.or) {
        return parseConditions(answers, item, showConsole);
      } else if (item.and) {
        return parseConditions(answers, item, showConsole);
      } else {
        if (showConsole) console.log('returning result', answers[item.ng_if_model], item.ng_if_value);
        return answers[item.ng_if_model] == item.ng_if_value;
      }
    }

    this.createAccountObj = function() {
      var self = this;
      var objToSend = {};
      var isVisible;
      objToSend.id = self.answers.id;
      objToSend.website_user_uuid = self.answers.website_user_uuid;
      objToSend.website_user_id = self.answers.website_user_id;
      if(!self.steps[self.current_step_index]) {
        return objToSend;
      }
      self.steps[self.current_step_index].rows.forEach(function (row){
        row.fields.forEach(function (field) {
          isVisible = true;
          if (field.ng_if) {
            isVisible = parseConditions(self.answers, field.ng_if);
          }
          if (!isVisible && self.answers[field.input.model] != null) {
            objToSend[field.input.model] = null;
          }
          else if (field.input.input_type == 'check') {
            for (var answer in field.input.answer){
              if (field.input.answer.hasOwnProperty(answer)){
                objToSend[answer] = field.input.answer[answer];

                if(field.input.maxlength && objToSend[answer]) {
                  objToSend[answer] = objToSend[answer].substring(0,field.input.maxlength);
                }
              }
            }
          } else {
            objToSend[field.input.model] = self.answers[field.input.model];

            if(field.input.maxlength && objToSend[field.input.model]) {
              objToSend[field.input.model] = objToSend[field.input.model].substring(0,field.input.maxlength);
            }
          }
        });
      });
      return objToSend;
    }

    this.prepareAndSendKycInstance = function(step, cb) {

			var step_to_save = []
			angular.forEach(step.rows, function(row){
				angular.forEach(row.fields, function(field){

					if(field.input.input_type == 'check'){
						angular.forEach(field.input.options, function(option) {
							var obj = {};
							obj._id = option._id;
							obj.model = field.input.model;
							obj.opt_model = option.model;
							obj.value = typeof self.answers[option.model] !== 'undefined' ? self.answers[option.model] : false;
							step_to_save.push(obj);
						});
					}else{
						var obj = {}

						obj._id = field._id
						obj.model = field.input.model
						obj.value = field.input.answer;
						step_to_save.push(obj)
					}

				});
			})

      var step_obj = {kyc_instance_id: KYCInstanceID.value, step_id: step._id, values: step_to_save}
      if (window.Fides && window.Fides.consent.analytics === true) {
        NwuiAnalyticsService.track('questionnaire-form-' + step.title + '-step-' + this.current_step_index, this.getAnalyticsData(step_obj.values));
      }

			return $http.put('/api/kyc_answer', step_obj);

    }

    this.prepareAndSendFormInstance = function(step, cb) {
      if(!step) {
        step = {
          rows: []
        }
      }
      var step_to_save = [];
      angular.forEach(step.rows, function(row){
        angular.forEach(row.fields, function(field){

          if(field.input.input_type == "check"){
            angular.forEach(field.input.options, function(option){
              var obj = {}
              obj._id = option._id
              obj.model = option.model
              obj.value = self.answers[option.model]
              step_to_save.push(obj)
            })

          } else {
            var obj = {}
            obj._id = field._id
            obj.model = field.input.model
            obj.value = field.input.answer;
            self.answers[field.input.model] = field.input.answer
            step_to_save.push(obj)
          }
        })
      })

      if (window.Fides && window.Fides.consent.analytics === true) {
        var step_obj = {form_instance_id: FormInstanceID.value, step_id: step._id, values: step_to_save}
        NwuiAnalyticsService.track('forms-form-' + step.analytics_tag + '-step-' + this.current_step_index, this.getAnalyticsData(step_obj.values));
      }

      return $http.put('/api/form_answer', {
        account_holder: self.createAccountObj(),
        step_obj: step_obj,
        analytics_tag: step.analytics_tag
      }).then(function(res) {
        if (res.data.redirect) {
          $window.location.href = res.data.redirect;
        }
        if (res.data.account_holder_data) {
          Object.keys(res.data.account_holder_data || {}).forEach(function(key, i) {
            self.answers[key] = res.data.account_holder_data[key];
          });
        }
        return self.answers;
      });
    };

    this.updateAnswers = function(answers) {
      var account_holder = {
        website_user_id: self.answers.website_user_id
      };
      Object.keys(answers || {}).forEach(function(key, i) {
        account_holder[key] = answers[key];
      })
      return $http.put('/api/form_answer', {
        account_holder: account_holder
      }).then(function(res) {
        Object.keys(answers || {}).forEach(function(key, i) {
          self.answers[key] = res.data.account_holder_data[key];
        })
        return self.answers;
      });
    }

    this.setFormReadOnly = function(formObjSteps) {
      formObjSteps.forEach(function(step){
        step.rows.forEach(function(row) {
          row.fields.forEach(function(field) {
            field.input.readonly = true;
          });
        });
      });
    }

    this.createIdsOnObject = function(id, uuid){
      self.answers.id = id;
      self.answers.website_user_uuid = uuid;
      self.answers.website_user_id = id;
    }

    this.getJointCode = function() {
      return $http.post('/api/access-codes', {
        type: 'UserInvitation'
      }).then(function(obj) {
        self.access_code = obj.data.value;
        return obj;
      });
    }

    this.getAnalyticsData = function(data) {
      if (Array.isArray(data)) {
        return data.reduce(function(acc, curr) {
          acc[(curr.model || 'value')] = curr.value;
          return acc;
        }, {});
      }
      return {};
    };

  }
]);